
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { SidebarMenu } from 'vue-sidebar-menu'
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'
import { expandCart } from '@/../common/utils/orderProcessor'
import AppHeader from '~/components/AppHeader'
import ModalDriverReport from '~/components/modals/ModalDriverReport'
import ModalSearchPostcode from '~/components/modals/ModalSearchPostcode'
import channels from '~/../common/mixins/channels'
import ModalDriversList from '~/components/modals/ModalDriversList'
import getOrdersMix from '@/mixins/getOrdersMix'
import alertWeb from '@/../common/mixins/alertWeb'
import redirect from '@/middleware/redirect'

export default {
  name: 'Default',
  components: {
    ModalSearchPostcode,
    SidebarMenu,
    AppHeader,
    ModalDriverReport,
    ModalDriversList
  },
  mixins: [channels, getOrdersMix, alertWeb],
  middleware: [redirect],
  data () {
    return {
      title: 'Dashboard',
      fetchOrdersInterval: null,
      dismissSecs: 3,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      alertMsg: '',
      postcode: '',
      menuCollapseState: false,
      mobileMenuCollapseState: true,
      showOnlineStatus: false,
      showOfflineStatus: false,
      menu: [
        {
          header: true,
          title: 'tops2go',
          hiddenOnCollapse: true
        },
        {
          href: '/',
          title: 'Dashboard',
          icon: {
            element: 'b-icon',
            attributes: { icon: 'app' }
          }
        },
        {
          href: '/ordering',
          title: 'Menu',
          icon: {
            element: 'b-icon',
            attributes: { icon: 'menu-app' }
          },
          hidden: this.$webPos ? this.$webPos : false
        },
        {
          href: '/orders',
          title: 'Orders',
          icon: {
            element: 'b-icon',
            attributes: { icon: 'card-list' }
          }
        },
        {
          href: '/archive',
          title: 'Archive',
          icon: {
            element: 'b-icon',
            attributes: { icon: 'archive' }
          }
        },
        {
          href: '/third-party-orders',
          title: '3rd Party Orders',
          icon: {
            element: 'b-icon',
            attributes: { icon: 'archive' }
          }
        },
        {
          href: '/z-report',
          title: 'Z Report',
          icon: {
            element: 'b-icon',
            attributes: { icon: 'card-text' }
          }
        },
        {
          title: 'Driver Report',
          icon: {
            element: 'b-icon',
            attributes: { icon: 'card-text' }
          }
        },
        {
          href: '/instructions',
          title: 'Instructions',
          icon: {
            element: 'b-icon',
            attributes: { icon: 'info-circle' }
          }
        },
        {
          href: '/news',
          title: 'News',
          icon: {
            element: 'b-icon',
            attributes: { icon: 'newspaper' }
          }
        },
        {
          href: '/support',
          title: 'Support',
          icon: {
            element: 'b-icon',
            attributes: { icon: 'people' }
          }
        },
        {
          href: '/settings',
          title: 'Settings',
          icon: {
            element: 'b-icon',
            attributes: { icon: 'gear' }
          },
          hidden: this.$webPos ? this.$webPos : false
        }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'currentStore',
      'activeOrders',
      'products',
      'ingredientGroups',
      'onlineUsers'
    ]),
    drivers () {
      return this.onlineUsers.filter(user => user.role === 'Driver')
    }
  },
  watch: {
    $route (newVal) {
      this.updateSidebarLink(newVal)
    },
    activeOrders (newVal, oldVal) {
      const oldActiveOrderIds = oldVal.filter(order => ['pending', 'paid'].includes(order.status)).map(order => order.id)
      const newActiveOrderIds = newVal.filter(order => ['pending', 'paid'].includes(order.status)).map(order => order.id)
      const newAddedOrderIds = newActiveOrderIds.filter(id => !oldActiveOrderIds.includes(id))
      if (newAddedOrderIds.length) {
        this.buzzer.play()
        newAddedOrderIds.forEach((id) => {
          this.showAlert('New order')
          this.printReceipt(newVal.find(order => order.id === id))
        })
        if (this.$route.name === 'archive') {
          this.$root.$emit('refreshArchive')
        }
      }
    },
    currentStore () {
      this.subscribeToStore()
    }
  },
  created () {
    this.$nuxt.$on('showAlert', $msg => this.showAlert($msg))

    this.$echo.connector.socket.on('connect', () => {
      clearInterval(this.fetchOrdersInterval)
      this.fetchOrdersInterval = null
    })

    this.$echo.connector.socket.on('connect_error', (socket) => {
      if (!this.fetchOrdersInterval) {
        this.startOrderIntervals()
      }
    })

    this.$echo.connector.socket.on('disconnect', () => {
      if (!this.fetchOrdersInterval) {
        this.startOrderIntervals()
      }
    })

    this.$echo.connector.socket.on('reconnect', () => {
      this.getOrders()
    })
  },
  mounted () {
    this.getStores()
    this.listenToCallerIds()
    this.subscribeToStore()
    this.onlineStatus()

    this.buzzer.addEventListener('ended', function () {
      this.currentTime = 0
      this.play()
    }, false)
    this.updateSidebarLink(this.$route)
    document.addEventListener('click', this.stopBuzzer)
  },
  methods: {
    ...mapActions(['getStores', 'resetPosOrdering', 'listenToCallerIds', 'setCurrentStore']),
    ...mapMutations(['addNews', 'setStaff', 'setStore', 'clearCart', 'updateNews', 'deleteNews', 'setOrder', 'setOrders', 'setOnlineUser', 'setOnlineUsers', 'removeOnlineUser']),
    async refresh () {
      if (this.currentStore) {
        this.showLoading()
        await this.setCurrentStore({ store_id: this.currentStore.id })
        this.hideLoading()
      }
    },
    updateSidebarLink (route) {
      setTimeout(() => {
        this.$refs.posSidebar.$children.map((item) => {
          item.active = false
          item.exactActive = false
          return item
        })
        this.mobileMenuCollapseState = true
        if (route.name === 'index') {
          this.title = 'Dashboard'
          this.$refs.posSidebar.$children[1].active = true
          this.$refs.posSidebar.$children[1].exactActive = true
        }
        if (route.path.includes('/ordering')) {
          this.title = 'Menu'
          this.$refs.posSidebar.$children[2].active = true
          this.$refs.posSidebar.$children[2].exactActive = true
        }
        if (route.path.includes('/orders')) {
          this.title = 'Orders'
          this.$refs.posSidebar.$children[3].active = true
          this.$refs.posSidebar.$children[3].exactActive = true
        }
        if (route.path.includes('/archive')) {
          this.title = 'Archive'
          this.$refs.posSidebar.$children[4].active = true
          this.$refs.posSidebar.$children[4].exactActive = true
        }
        if (route.path.includes('/third-party-orders')) {
          this.title = 'Third party orders'
          this.$refs.posSidebar.$children[5].active = true
          this.$refs.posSidebar.$children[5].exactActive = true
        }
        if (route.path.includes('/z-report')) {
          this.title = 'Z-report'
          this.$refs.posSidebar.$children[6].active = true
          this.$refs.posSidebar.$children[6].exactActive = true
        }
        if (route.path.includes('/instructions')) {
          this.title = 'Instructions'
          this.$refs.posSidebar.$children[8].active = true
          this.$refs.posSidebar.$children[8].exactActive = true
        }
        if (route.path.includes('/news')) {
          this.title = 'News'
          this.$refs.posSidebar.$children[9].active = true
          this.$refs.posSidebar.$children[9].exactActive = true
        }
        if (route.path.includes('/support')) {
          this.title = 'Support'
          this.$refs.posSidebar.$children[10].active = true
          this.$refs.posSidebar.$children[10].exactActive = true
        }
        if (route.path.includes('/settings')) {
          this.title = 'Settings'
          this.$refs.posSidebar.$children[11].active = true
          this.$refs.posSidebar.$children[11].exactActive = true
        }
        this.$refs.posSidebar.$forceUpdate()
      }, 10)
    },
    countDownChanged (dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert (msg) {
      this.alertMsg = msg
      this.dismissCountDown = this.dismissSecs
    },
    menuItemClick (event, item, node) {
      if (item.title === 'Driver Report') {
        this.$bvModal.show('modalDriverReport')
      }
    },
    onToggleCollapse (collapsed) {
      this.menuCollapseState = collapsed
    },
    logout () {
      this.$auth.setUser({})
      this.resetPosOrdering()
      this.clearCart()
      this.setStaff()
      this.setStore()
      this.setOrders([])
      this.setOnlineUsers([])
      this.$auth.strategies.local.reset()
      this.$router.push('/login')

      if (this.$ipcRenderer) {
        this.$ipcRenderer.send('delete_login')
      }
      // location.reload()
    },
    appendStoreNameToHeader () {
      this.menu[0].title = this.menu[0].title + (this.currentStore ? ' - ' + this.currentStore.name : '')
    },
    startOrderIntervals () {
      this.fetchOrdersInterval = setInterval(() => {
        this.getOrders()
      }, 30000)
    },
    processOrder (order) {
      if (order.order_data) {
        order.cart = expandCart(JSON.parse(order.order_data), this.products, this.ingredientGroups, true)
      } else {
        order.cart = []
      }
      this.setOrder(order)
    },
    subscribeToStore () {
      if (this.currentStore) {
        this.currentStoreChannel()
          .here((users) => {
            this.setOnlineUsers(users.filter(user => user.hashedId !== this.$auth.user.hashedId))
          })
          .joining((user) => {
            this.setOnlineUser(user)
          })
          .leaving((user) => {
            this.removeOnlineUser(user)
          })
          .listen('OrderCreated', this.processOrder)
          .listen('ScheduledOrder', this.processOrder)

        this.newsChannel(this.currentStore.id)
          .listen('NewsCreated', (data) => {
            this.addNews(data.news)
            this.showAlert('News added')
          })
          .listen('NewsUpdated', (data) => {
            this.updateNews(data.news)
          })
          .listen('NewsDeleted', (data) => {
            this.deleteNews(data.news)
          })

        this.fetchOrders()
        this.appendStoreNameToHeader()
      }
    },
    onlineStatus () {
      window.addEventListener('online', (ev) => {
        this.showOfflineStatus = false
        this.showOnlineStatus = true
        setTimeout(() => {
          this.showOnlineStatus = false
          window.location.reload()
        }, 5000)
      }, false)
      window.addEventListener('offline', (ev) => {
        this.showOfflineStatus = true
        this.showOnlineStatus = false
      }, false)
    }
  }
}
