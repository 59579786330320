import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['currentStore'])
  },
  created () {
    this.$echo.connector.options.auth.headers.Authorization = this.$auth.strategy.token.get()
  },
  methods: {
    leaveChannel (orderId) {
      return this.$echo.leaveChannel(`orders.${orderId}`)
    },
    orderChannel (orderId) {
      return this.$echo.channel(`orders.${orderId}`)
    },
    driverStoresChannel () {
      return this.$echo.join(`driver-stores.${this.$auth.user.hashedId}`)
    },
    driverStoresLeaveChannel () {
      return this.$echo.leave(`driver-stores.${this.$auth.user.hashedId}`)
    },
    storesChannel (storeId) {
      return this.$echo.join(`stores.${storeId}`)
    },
    newsChannel (storeId) {
      return this.$echo.private(`news.${storeId}`)
    },
    currentStoreChannel () {
      return this.storesChannel(this.currentStore.id)
    },
    connectToMultipleStores (storeIds) {
      const cons = []
      storeIds.forEach((id) => {
        cons.push(this.storesChannel(id))
      })

      return cons
    },
    sendDriverStatus (storeId, status) {
      this.storesChannel(storeId)
        .whisper('updateStatus', {
          hashedId: this.$auth.user.hashedId,
          status: status
        })

      this.$api.sendDriverStatus({ hashedId: this.$auth.user.hashedId, storeId, status})
    },
    sendDeliveryRequest (order, driverIdHashed) {
      this.storesChannel(this.currentStore.id)
      .whisper(`deliveryRequest-${driverIdHashed}`, {
          storeId: this.currentStore.id,
          storeName: this.currentStore.name,
          order
        })
      },
    driverAccepted(storeId, staffId, status = 'delivery', isAccept = 'accepted') {
      this.storesChannel(storeId)
          .whisper('driverAccepted', {
            hashedId: this.$auth.user.hashedId,
            deliveredBy: staffId,
            status: status,
            accept: isAccept
          })
    }
  }
}
