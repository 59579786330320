
import { mapGetters, mapMutations } from 'vuex'
import alert from '~/static/alert.mp3'
import AppHeader from '~/components/AppHeader'
import channels from '~/../common/mixins/channels'
import ModalDriversList from '~/components/modals/ModalDriversList'
import alertWeb from '@/../common/mixins/alertWeb'
import { expandCart } from '@/../common/utils/orderProcessor'
import getOrdersMix from '@/mixins/getOrdersMix'

export default {
  name: 'Kitchen',
  components: {
    AppHeader,
    ModalDriversList
  },
  mixins: [channels, alertWeb, getOrdersMix],
  data () {
    return {
      buzzer: new Audio(alert),
      onlineUsers: []
    }
  },
  computed: {
    ...mapGetters(['products', 'ingredientGroups', 'freePizzaRewardDeal', 'kitchenOrders'])
  },
  watch: {
    kitchenOrders (newVal, oldVal) {
      const oldKitchenOrderIds = oldVal.filter(order => order.status === 'accepted').map(order => order.id)
      const newKitchenOrderIds = newVal.filter(order => order.status === 'accepted').map(order => order.id)
      const newAddedOrderIds = newKitchenOrderIds.filter(id => !oldKitchenOrderIds.includes(id))
      if (newAddedOrderIds.length) {
        this.buzzer.play()
        this.showAlert('New order')
      }
    }
  },
  created () {
    this.$nuxt.$on('showAlert', $msg => this.showAlert($msg))

    this.$echo.connector.socket.on('connect', () => {
      clearInterval(this.fetchOrdersInterval)
      this.fetchOrdersInterval = null
    })

    this.$echo.connector.socket.on('connect_error', (socket) => {
      if (!this.fetchOrdersInterval) {
        this.startOrderIntervals()
      }
    })

    this.$echo.connector.socket.on('disconnect', () => {
      if (!this.fetchOrdersInterval) {
        this.startOrderIntervals()
      }
    })

    this.$echo.connector.socket.on('reconnect', () => {
      this.getOrders()
    })

    this.currentStoreChannel()
      .here((users) => {
        this.onlineUsers = users.filter(user => user.hashedId !== this.$auth.user.hashedId)
      })
      .joining((user) => {
        this.onlineUsers.push(user)
      })
      .leaving((user) => {
        this.onlineUsers = this.onlineUsers.filter(u => u.hashedId !== user.hashedId)
      })
      .listen('OrderCreated', (order) => {
        if (order.order_data) {
          order.cart = expandCart(JSON.parse(order.order_data), this.products, this.ingredientGroups, true, this.freePizzaRewardDeal)
        } else {
          order.cart = []
        }
        this.setOrder(order)
        this.leaveChannel(order.id)
        this.orderChannel(order.id).listen('OrderUpdated', (resp) => {
          resp.cart = expandCart(JSON.parse(resp.order_data), this.products, this.ingredientGroups, true, this.freePizzaRewardDeal)
          this.setOrder(resp)
        })
      })
  },
  mounted () {
    this.showLoading()
    this.fetchOrders().then(() => {
      this.orders.forEach((order) => {
        this.orderChannel(order.id).listen('OrderUpdated', (resp) => {
          resp.cart = expandCart(JSON.parse(resp.order_data), this.products, this.ingredientGroups, true, this.freePizzaRewardDeal)
          this.setOrder(resp)
        })
      })
    }).finally(() => {
      this.hideLoading()
    })
    this.buzzer.addEventListener('ended', function () {
      this.play()
    }, false)
    document.addEventListener('click', this.stopBuzzer)
  },
  methods: {
    ...mapMutations(['setOrder']),
    stopBuzzer () {
      this.buzzer.pause()
    },
    startOrderIntervals () {
      this.fetchOrdersInterval = setInterval(() => {
        this.fetchOrders().then(() => {
          this.orders.forEach((order) => {
            this.orderChannel(order.id).stopListening('OrderUpdated')
            this.orderChannel(order.id).listen('OrderUpdated', (resp) => {
              resp.cart = expandCart(JSON.parse(resp.order_data), this.products, this.ingredientGroups, true, this.freePizzaRewardDeal)
              this.setOrder(resp)
            })
          })
        })
      }, 30000)
    }
  }
}
