import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'
import products from '../../common/store/products'
import time from '../../common/store/time'
import cart from '../../common/store/cart'
import address from '../../common/store/address'
import voucher from '../../common/store/voucher'
import user from '../../common/store/user'
import orderHistory from '../../common/store/orderHistory'
import orders from '../../common/store/orders'
import stores from '../../common/store/stores'
import store from '../../common/store/data'
import info from '../../common/store/info'
import news from './news'
import staff from './staff'
import caller from './caller'
import settings from './settings'

const localStorageData = (new VuexPersist({ // localStorage
  modules: ['user', 'staff', 'store', 'products', 'cart', 'address', 'info', 'voucher', 'orders', 'settings']
})).plugin

const createStore = () => {
  return new Vuex.Store({
    modules: {
      user,
      orders,
      products,
      news,
      staff,
      store,
      stores,
      time,
      cart,
      address,
      voucher,
      orderHistory,
      info,
      caller,
      settings
    },
    plugins: [localStorageData]
  })
}
export default createStore
