import { mapGetters, mapActions } from 'vuex'
import alert from '~/static/alert.mp3'

export default {
  data () {
    return {
      buzzer: new Audio(alert)
    }
  },
  computed: {
    ...mapGetters(['orders', 'printIncomingOrders', 'doublePrint'])
  },
  methods: {
    ...mapActions(['fetchOrders']),
    stopBuzzer () {
      this.buzzer.pause()
    },
    printReceipt (order) {
      if (order.receipt) {
        const printContent = order.receipt
        if (this.$nativePrinter && this.printIncomingOrders) {
          this.$nativePrinter({ type: 'base64', data: printContent, doublePrint: this.doublePrint })
        }
        if (this.$webPrinter) {
          this.$webPrinter({
            type: 'raw-html',
            printable: atob(printContent),
            orderId: order.id,
            storeId: order.store.id,
            fromIncoming: true
          })
        }
      }
    },
    getOrders () {
      this.fetchOrders()
    }
  }
}
