import prntr from 'prntr'

export default (context, inject) => {
  inject('webPrinter', (data) => {
    if (context.$config.webPrinter) {
      if (typeof data.fromIncoming === 'undefined') {
        context.$api.sendToPrinter({
          order_id: data.orderId,
          store_id: data.storeId,
          receipt: btoa(data.printable)
        })
      }
    } else {
      prntr(data)
    }
  })
}
