
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ModalDriverReport',
  data () {
    return {
      hiddenIds: []
    }
  },
  computed: {
    ...mapGetters(['zReportOrders', 'currentTime', 'timezone', 'currentStore']),
    currentDate () {
      if (this.currentTime && this.timezone) {
        return this.$moment(this.currentTime, 'X').tz(this.timezone).format('dddd Do of MMMM h:mm A')
      }
      return ''
    },
    driverReport () {
      const data = {}

      this.zReportOrders.forEach(function (order) {
        if (order.delivered_by === null) {
          return
        }

        const driver = data[order.delivered_by]

        if (driver) {
          if (order.payment_type === 'card') {
            driver.cardOrders = +1
          } else if (order.payment_type === 'cash') {
            driver.cashOrders = driver.cashOrders + 1
            driver.cash = parseFloat(driver.cash) + parseFloat(order.grandTotal)
            driver.cash = driver.cash.toFixed(2)
            if (!driver.cashOnHand && order.driver_cash_off === '0') {
              driver.cashOnHand = true
            }
          }
          driver.totalOrders += 1
        } else {
          const driver = {
            id: order.delivered_by,
            name: order.driver_name,
            totalOrders: 1,
            cashOnHand: false
          }

          if (order.payment_type === 'card') {
            driver.cardOrders = 1
            driver.cashOrders = 0
            driver.cash = 0
          } else if (order.payment_type === 'cash') {
            driver.cardOrders = 0
            driver.cashOrders = 1
            driver.cash = order.grandTotal
            driver.cashOnHand = order.driver_cash_off === '0'
          }

          data[order.delivered_by] = driver
        }
      })

      return Object.values(data)
    }
  },
  methods: {
    ...mapActions(['driverCashOff', 'fetchOrders']),
    printReport () {
      const printContent = '<html lang="en"><body>' + this.$refs.driverReportTable.innerHTML + '</body></html>'
      if (this.$nativePrinter) {
        this.$nativePrinter({ type: 'html', data: printContent, report: true })
      }
      if (this.$webPrinter) {
        this.$webPrinter({
          type: 'raw-html',
          printable: printContent,
          storeId: this.currentStore.id,
          orderId: 'driverReport'
        })
      }
    },
    cashOff (driverId) {
      this.driverCashOff({ delivered_by: driverId, store_id: this.currentStore.id })
      this.hiddenIds.push(driverId)

      const self = this
      this.fetchOrders().then(function () {
        self.hiddenIds = []
      })
    }
  }
}
