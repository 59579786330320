const state = () => ({
  news: []
})

const mutations = {
  setNews (state, news) {
    state.news = news
  },
  addNews (state, news) {
    state.news.unshift(news)
  },
  updateNews (state, news) {
    state.news = state.news.map(n => n.id === news.id ? { ...news } : n)
  },
  deleteNews (state, news) {
    state.news = state.news.filter(n => n.id !== news.id)
  }
}

const actions = {
  async fetchNews (context) {
    await this.$api.getNews({ store_id: context.getters.currentStore.id }).then((resp) => {
      const news = resp.data.data.map((news) => {
        news.message = news.message.replaceAll('src="/storage/', 'src="' + this.$config.siteUrl + '/storage/')
        return news
      })
      context.commit('setNews', news)
    })
  }
}

const getters = {
  news: state => state.news
}

export default {
  state,
  getters,
  actions,
  mutations
}
