const state = () => ({
  numbers: [
    {
      phone: null,
      status: null
    },
    {
      phone: null,
      status: null
    },
    {
      phone: null,
      status: null
    },
    {
      phone: null,
      status: null
    },
    {
      phone: null,
      status: null
    },
    {
      phone: null,
      status: null
    }
  ]
})

const mutations = {
  addNumber (state, number) {
    if (state.numbers.filter(n => n.phone === number).length === 0) {
      state.numbers.unshift({
        phone: number,
        status: 'onCall'
      })
    }

    if (state.numbers.length > 6) {
      const emptyEl = state.numbers.filter(number => number.status === null)
      if (emptyEl.length) {
        state.numbers.splice(state.numbers.indexOf(emptyEl[0]), 1)
      }
    }
  },

  removeNumber (state, number) {
    state.numbers = state.numbers.filter(el => el.phone !== number)

    while (state.numbers.length < 6) {
      state.numbers.push({
        phone: null,
        status: null
      })
    }
  }
}

const actions = {
  listenToCallerIds (context) {
    /*
    window.addNumber = (number) => {
      context.commit('addNumber', number)
    }

    window.removeNumber = (number) => {
      context.commit('removeNumber', number)
    }
     */
    if (this.$ipcRenderer) {
      this.$ipcRenderer.on('tapi_message', (event, data) => {
        if (data.status === 'LINECALLSTATE_OFFERING') {
          context.commit('addNumber', data.number)
        }

        if (data.status === 'LINECALLSTATE_DISCONNECTED') {
          context.commit('removeNumber', data.number)
        }
      })
    }
  }
}

const getters = {
  numbers: state => state.numbers
}

export default {
  state,
  getters,
  actions,
  mutations
}
