export default ({ store }) => {
  store.dispatch('getServerTime')
  setInterval(() => {
    store.dispatch('getServerTime')
  }, 60000)

  setInterval(() => {
    window.location.reload()
  }, 3600000)
}
