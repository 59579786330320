
import { mapGetters, mapActions, mapMutations } from 'vuex'
import channels from '../../../common/mixins/channels'
import { expandCart } from '@/../common/utils/orderProcessor'
import alertWeb from '@/../common/mixins/alertWeb'

export default {
  name: 'ModalDriversList',
  mixins: [channels, alertWeb],
  props: ['drivers'],
  data () {
    return {
      driverSelected: false,
      driverStatus: null,
      requestSent: false
    }
  },
  computed: {
    ...mapGetters(['currentStore', 'orderForDriver', 'products', 'ingredientGroups', 'freePizzaRewardDeal'])
  },
  mounted () {
    if (this.currentStore) {
      this.currentStoreChannel().listenForWhisper('updateStatus', (data) => {
        this.updateUserStatus(data.hashedId, data.status)
      }).listenForWhisper('driverAccepted', (data) => {
        if (data.accept === 'accepted') {
          this.driverSelected = false
          this.requestSent = false

          if (!this.$nuxt.$route.path.includes('/kitchen')) {
            this.$nuxt.$emit('showAlert', 'Driver accepted the order')
          }
          this.updateOrder({
            id: this.orderForDriver.id,
            data: {
              status: 'to_be_delivered',
              delivered_by: data.deliveredBy
            }
          })
          this.$root.$emit('bv::hide::modal', 'ModalDriversList')
        }
      })
    }
  },
  methods: {
    ...mapActions(['updateOrder']),
    ...mapMutations(['setOrder']),
    skipDiverSelection () {
      this.showLoading()
      this.driverSelected = false
      this.requestSent = false
      this.updateOrder({
        id: this.orderForDriver.id,
        data: {
          status: 'to_be_delivered'
        }
      }).then((response) => {
        if (response.status === 'Success') {
          if (response.data.order_data) {
            response.data.cart = expandCart(JSON.parse(response.data.order_data), this.products, this.ingredientGroups, true, this.freePizzaRewardDeal)
          } else {
            response.data.cart = []
          }
          this.setOrder(response.data)
        }
      }).finally(() => {
        this.hideLoading()
        this.$root.$emit('bv::hide::modal', 'ModalDriversList')
      })
    },
    isActive (driver) {
      return driver.status === 'available'
    },
    selectDriver (hashedId) {
      this.driverSelected = hashedId
    },
    updateUserStatus (hashedId, status) {
      this.drivers.forEach((driver) => {
        if (driver.hashedId === hashedId) {
          driver.status = status
        }

        if (this.driverSelected === driver.hashedId) {
          this.unselectDrivers()
        }
      })
    },
    unselectDrivers () {
      this.driverSelected = false
    },
    sendRequestToDriver () {
      this.sendDeliveryRequest(this.orderForDriver, this.driverSelected)
    }
  }
}
