// inject the api definitions
import api from '../../common/api'

export default function ({ $axios, store }, inject) {
  const http = {
    get (url, requestData) {
      return $axios.get(url, { params: requestData })
    },
    post (url, requestData) {
      return $axios.post(url, requestData)
    },
    put (url, requestData) {
      return $axios.put(url, requestData)
    },
    patch (url, requestData) {
      return $axios.patch(url, requestData)
    },
    delete (url, requestData) {
      return $axios.delete(url, { params: requestData })
    }
  }
  inject('api', api(http))
}
