
import { gmapApi } from 'vue2-google-maps'

export default {
  name: 'ModalSearchPostcode',
  props: {
    postcode: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      lat: 0,
      lng: 0
    }
  },
  computed: {
    google: gmapApi,
    mapPointer () {
      return {
        lat: this.lat,
        lng: this.lng
      }
    }
  },
  mounted () {
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if (modalId === 'modalSearchPostcode') {
        this.setLatLng()
      }
    })
  },
  methods: {
    setLatLng () {
      this.$gmapApiPromiseLazy().then(() => {
        const geocoder = new this.google.maps.Geocoder()

        const self = this
        geocoder.geocode({
          componentRestrictions: {
            country: 'UK',
            postalCode: this.postcode
          }
        }, function (results, status) {
          if (status === 'OK') {
            self.lat = results[0].geometry.location.lat()
            self.lng = results[0].geometry.location.lng()
          }
        })
      })
    }
  }
}
