const state = () => ({
  staff: null,
  currentRole: null,
  role: null,
  store: null,
  rolesWithStore: [],
  onlineUsers: []
})

const mutations = {
  setStaff (state, staff) {
    state.staff = staff
  },
  setRole (state, role) {
    state.currentRole = role
  },
  setRolesWithStore (state, rolesWithStore) {
    state.rolesWithStore = rolesWithStore
  },
  setOnlineUser (state, user) {
    if (!state.onlineUsers.map(u => u.hashedId).includes(user.hashedId)) {
      state.onlineUsers.push(user)
    }
  },
  setOnlineUsers (state, users) {
    state.onlineUsers = users.filter((obj, index) => users.findIndex(item => item.hashedId === obj.hashedId) === index)
  },
  removeOnlineUser (state, user) {
    state.onlineUsers = state.onlineUsers.filter(u => u.hashedId !== user.hashedId)
  }
}

const actions = {
  async fetchStaff ({ commit }) {
    await this.$api.getStaff({ id: this.$auth.user.id }).then((resp) => {
      commit('setStaff', resp.data.data)
    })
  },
  setCurrentRole (context, currentRole) {
    context.commit('setRole', currentRole)
  },
  setRolesWithStore (context, roles) {
    context.commit('setRolesWithStore', roles)
  }
}

const getters = {
  staff: state => state.staff,
  currentRole: state => state.currentRole,
  rolesWithStore: state => state.rolesWithStore,
  onlineUsers: state => state.onlineUsers
}

export default {
  state,
  getters,
  actions,
  mutations
}
