

import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'AppHeader',
  props: ['title'],
  computed: {
    ...mapGetters(['currentRole'])
  },
  methods: {
    ...mapMutations(['setUser', 'setStaff', 'setStore']),
    logout () {
      this.$auth.setUser({})
      this.setUser()
      this.setStaff()
      this.setStore()
      this.$auth.strategies.local.reset()
      this.$router.push('/login')
    }
  }
}
