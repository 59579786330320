const state = () => ({
  printIncomingOrders: false,
  doublePrint: false
})

const mutations = {
  setSetting (state, setting) {
    state[setting.name] = setting.value
  }
}

const getters = {
  printIncomingOrders: state => state.printIncomingOrders,
  doublePrint: state => state.doublePrint
}

export default {
  state,
  getters,
  mutations
}
